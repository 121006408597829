import React from "react";
import useLang from "../../contexts/Language/LanguageContext";

export default function TransactionSuccess() {
  const lang = useLang();
  return (
    <div>
      <h1>{lang === "mne" ? "Transakcija uspješna" : "Transaction success"}</h1>
      <p>
        {lang === "mne"
          ? `Čestitamo što ste postali donator/ka I MI Boke!
            Možete se pridružiti našoj Viber zajednici i/ili se prijaviti za Newsletter. 
            Putem ovih kanala, kao i na veb sajtu i društvenim mrežama I MI Boke redovno ćemo objavljivati iznose 
            donacija i podatke o njihovom trošenju. Za više informacija nas kontaktirajte putem e-pošte, telefona, Vibera, 
            WhatsAppa i/ili Signala. Pozivamo Vas da donirate kada god ste u mogućnosti, 
            a do tada se zahvaljujemo u ime naših korisnika/ca na velikodušnosti!`
          : `Congratulations, 
            you have become the IYDB's donor! 
            You can join our Viber community 
            and/or subscribe for the Newsletter. Through these channels, as well as 
            through the IYDB's website and social networks, we will regularly publish the amounts of 
            donations and data on their spending. For more information, contact us via email, phone,
             Viber, WhatsApp and/or Signal. 
            We invite you to donate whenever you are able, 
            and in the meantime, we thank you on behalf of our users for your generosity! `}
      </p>
    </div>
  );
}
